import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  // getTariffs(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingPortalTariffs');
  //   const config = props.query ?
  //     {
  //       params: {
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/tariff`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalTariffsList', response.data.data);
  //         ctx.$store.commit('pushPortalTariffsList', response.data.data);
  //         ctx.totalResultTariffs = response.data.total;
  //         if (ctx.$store.state.portalTariffsList.length >= response.data.total) {
  //           ctx.canLoadMoreTarrifs = false;
  //         } else {
  //           ctx.canLoadMoreTariffs = true;
  //         }
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalTariffs');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalTariffs');
  //     }
  //   );
  // },

  getTariffs(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalTariffs');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
            location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {        params: {
            location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          // console.log('is zero ')
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/tariff`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalTariffsList', response.data.data);
          ctx.$store.commit('pushPortalTariffsList', response.data.data);
          ctx.totalResultTariffs = response.data.total;
          if (ctx.$store.state.portalTariffsList.length >= response.data.total) {
            ctx.canLoadMoreTarrifs = false;
          } else {
            ctx.canLoadMoreTariffs = true;
          }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalTariffs');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalTariffs');
      }
    );
  },

  createTariff(ctx, tariff) {
    ctx.$store.commit('toggleMutePortalTariffEdit');
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/tariff`, {
        request: [tariff]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('tariffs.notificationTariffCreatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalTariffEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalTariffEdit');
        }
      );
  },

  updateTariff(ctx, tariff) {
    ctx.$store.commit('toggleMutePortalTariffEdit');
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/tariff`, {
        request: [tariff]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('tariffs.notificationTariffUpdatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalTariffEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalTariffEdit');
        }
      );
  },

  deleteTariff(ctx, tariff) {
    // ctx.deletingProfileModal = false;
    ctx.$store.commit('toggleMutePortalTariffEdit');
    Vue.axios
      .delete(`${RESTAPI || ''}/api/portal/admin/tariff`, {
        params: { ids: [tariff.id] }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('tariffs.notificationTariffDeletedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalTariffEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalTariffEdit');
        }
      );
  },
  getSubscriptionsFromUserAccounts(ctx) {
    ctx.subscriptionsAndTariffsList = [];
    ctx.$store.commit('setGetSubscriptionsFromUserAccountsRequestBegin');
    const config = {
      params: {
        limit: 10000,
        q: { type: { $eq: 'subscription' } },
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
      }
    };

    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/tariff`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalTariffsList', response.data.data);
          ctx.subscriptionsAndTariffsList = JSON.parse(JSON.stringify(response.data.data));
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('setGetSubscriptionsFromUserAccountsRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        // ctx.error = err;
        ctx.$store.commit('setGetSubscriptionsFromUserAccountsRequestEnd');
      }
    );
  }
};
