<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12  d-flex flex-column">
      <button class="btn btn-info btn-md ml-auto" @click="openModal(true)" v-if="!isDisable">
        {{ $t('tariffs.add') }}
      </button>

<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[-->
<!--          { value: 'name', label: $t('tariffs.name') },-->
<!--          { value: 'amount', label: $t('tariffs.amount') },-->
<!--          { value: 'speed', label: $t('tariffs.speed') },-->
<!--          { value: 'session', label: $t('tariffs.session') },-->
<!--          { value: 'type', label: $t('tariffs.tariffTypeCaption') }-->
<!--        ]"-->
<!--      />-->
      <Table-pagination-v3
        v-if="showTablePaginationV3Component"
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[
          { value: 'name', label: $t('tariffs.name') },
          { value: 'amount', label: $t('tariffs.amount') },
          { value: 'speed', label: $t('tariffs.speed') },
          { value: 'session', label: $t('tariffs.session') },
          { value: 'type', label: $t('tariffs.tariffTypeCaption') }
        ]"
      />

    </div>
              <div class="col-lg-4" >
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <button
                      v-if="isFiltersActive"
                      class="btn-outline-secondary btn btn-sm  new-modern-style-btn-in-filters mt-0"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </button>
                  </div>
                </div>
              </div>

    <div class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portaltariffs" v-if="isTariffsLoading"></div>-->
        <div v-if="isTariffsLoading || isPortalProfilesLoading"><Loader-spinner></Loader-spinner></div>
        <div class="card-header">
          <i class="glyphicons glyphicons-coins glyphicons--shrink mr-0"></i>
          {{ $t('tariffs.title') }}
          <span class="text-muted ml-3" v-if="tariffsList.length">
            {{ this.tariffsList.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalResultTariffs }}
          </span>
        </div>
        <div class="card-block card-block__table table-responsive">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
            <div class="col-lg-12"> -->
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('tariffs.name') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('tariffs.tariffTypeCaption') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('tariffs.profileCaptionInTable') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('tariffs.amount') }}</th>
                <th scope="col" class="text-nowrap align-middle">
                  {{ $t('tariffs.speed') }} ({{ $t('general.mbit') }})
                </th>
                <th scope="col" class="text-nowrap align-middle">
                  {{ $t('tariffs.session') }} ({{ $t('general.insec') }}.)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tariff, index) in tariffsList" :key="tariff.id" @click="openModal(false, tariff)">
                <td class="align-middle">
                  <span>{{ index + 1 }}</span>
                </td>
                <td class="align-middle">
                  <span>
                    {{ tariff.name }}
                  </span>
                </td>
                <td class="align-middle">
                  <span>
                    {{ getTariffTypeWithI18n(tariff.type) }}
                  </span>
                </td>
                <td class="align-middle">
                  <span v-if="getProfileNameByID(tariff.profile) !== ''">
                    {{ getProfileNameByID(tariff.profile) }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span>
                    {{ tariff.amount }}
                  </span>
                </td>
                <td class="align-middle">
                  <span>
                    {{ tariff.speed }}
                  </span>
                </td>
                <td class="align-middle">
                  <span>
                    {{ tariff.session }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div> -->
          <div
            v-if="
              this.canLoadMoreTarrifs && this.tariffsList.length && this.totalResultTariffs - this.tariffsList.length
            "
            class="card-block text-center p-0"
          >
            <button class="btn btn-block" @click="loadMore">
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultTariffs - this.tariffsList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultTariffs - this.tariffsList.length }}</span>
              {{ this.$t('portal.paginationTariffsOutOf') }} {{ this.totalResultTariffs - this.tariffsList.length }}
            </button>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <modal
      :backdrop="false"
      :title="isNew ? this.$t('tariffs.new') : this.$t('tariffs.edit')"
      @click.native="handleModalClick"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{ isNew ? $t('tariffs.new') : $t('tariffs.edit') }}
      </h4>

      <div class="form-group">
        <label for="tariff-type" class="">{{ $t('tariffs.tariffTypeCaption') }}</label>
        <div v-if="!isNew">
          <span>
            <b>{{ getTariffTypeWithI18n(tariff.type) }}</b>
          </span>
        </div>
        <div v-else>
          <select id="tariff-type" v-model="tariff.type" class="form-control" @change="tariffTypeSelectorHandler">
            <option v-for="type in tariffsTypesList" :key="type" :value="type">
              {{ getTariffTypeWithI18n(type) }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group mb-2">
          <label for="profile" class="required-mark">{{ $t('tariffs.profileField') }}</label>

          <Multiselect
            v-model="selectedInSelectorProfile"
            class="profile-selector"
            :multiple="false"
            :options="portalProfilesList || []"
            :closeOnSelect="true"
            :hideSelected="true"
            trackBy="id"
            label="name"
            :data-vv-as="$t('tariffs.profileField')"
            id="profile"
            data-vv-name="profile"
            :placeholder="$t('tariffs.selectProfile')"
            :selectLabel="$t('tariffs.profileSelectorTip')"
            :disabled="isDisable"
            :class="{ 'multiselect-disabled': isDisable, 'is-danger': errors.has('profile') }"
            :allowEmpty="true"
            @input="profileSelectHandler"
          >
            <template v-slot:noOptions>
              {{ $t('general.noDataToDisplay') }}
            </template>
            <template v-slot:noResult>
              {{ $t('general.noResultForSearch') }}
            </template>
          </Multiselect>
          <span
            v-tooltip.top-center="{ content: errors.first('profile') }"
            v-show="errors.has('profile')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
          <!--          <span v-show="errors.has('profile')" class="help is-danger">-->
          <!--            {{ errors.first('profile') }}-->
          <!--          </span>-->
        </div>
      </div>

      <div class="form-group">
        <label for="name" class="required-mark">{{ $t('tariffs.name') }}</label>
        <input
          id="name"
          name="name"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="tariff.name"
          v-validate="'required'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('name') }"
          v-show="errors.has('name')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div class="form-group">
        <label for="amount" class="required-mark">{{ $t('tariffs.amount') }}</label>
        <input
          id="amount"
          name="amount"
          type="number"
          class="form-control input"
          :readonly="isDisable"
          v-model="tariff.amount"
          v-validate="'required|min_value:0|integer'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('amount') }"
          v-show="errors.has('amount')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div class="form-group">
        <label for="speed" class="required-mark">
          {{ $t('tariffs.speed') }}
          <small class="text-muted">({{ $t('general.mbit') }})</small>
        </label>
        <input
          id="speed"
          name="speed"
          type="number"
          class="form-control input"
          :readonly="isDisable"
          v-model="tariff.speed"
          v-validate="'required|min_value:0|integer'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('speed') }"
          v-show="errors.has('speed')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div class="form-group">
        <label for="session" class="required-mark">
          {{ $t('tariffs.session') }}
          <small class="text-muted">({{ $t('general.insec') }}.)</small>
        </label>
        <input
          id="session"
          name="session"
          type="number"
          class="form-control input"
          :readonly="isDisable"
          v-model="tariff.session"
          v-validate="'required|min_value:0|integer'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('session') }"
          v-show="errors.has('session')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>
      <transition name="transitionINtariffsModal" mode="out-in">
        <div class="form-group" v-if="isSubscribtionOpenedInModal">
          <label for="recurring-switcher" class="">{{ $t('tariffs.recurringPeriodCaption') }}</label>
          <div class="ml-0 mt-h">
            <Switch-component
              @input="recurringSwitcherSwitchedHandler"
              :id="'recurring-switcher'"
              v-model="tariff.recurring"
              :disabled="isDisable"
              :label="$t('tariffs.recurringSwitcherCaption')"
            />
          </div>
        </div>
      </transition>
      <transition name="transitionINtariffsModal" mode="out-in">
        <div class="form-group" v-if="isSubscribtionOpenedInModal && tariff.recurring">
          <label for="recurring-type" class="">{{ $t('tariffs.recurringPeriodCaption') }}</label>
          <div>
            <select :readonly="isDisable" id="recurring-type" v-model="tariff.recurring_period" class="form-control">
              <option v-for="period in recurringPeriodsList" :key="period" :value="period">
                {{ getRecurringPeriodWithI18n(period) }}
              </option>
            </select>
          </div>
        </div>
      </transition>
      <transition name="transitionINtariffsModal" mode="out-in">
        <div class="form-group" v-if="isSubscribtionOpenedInModal">
          <label for="description" class="">{{ $t('tariffs.subscriptionDescriptionCaption') }}</label>
          <div class="d-flex flex-column">
            <div class="btn-group float-left" data-toggle="button">
              <label class="btn btn-outline-primary" :class="{ active: selectedLangForDescriiption === 'ru' }">
                <input type="radio" name="ru" @click="selectLangForDescriiptionHandler('ru')" />
                {{ $t('tariffs.locale.ru') }}
              </label>
              <label class="btn btn-outline-primary" :class="{ active: selectedLangForDescriiption === 'en' }">
                <input type="radio" name="en" @click="selectLangForDescriiptionHandler('en')" />
                {{ $t('tariffs.locale.en') }}
              </label>
              <label class="btn btn-outline-primary" :class="{ active: selectedLangForDescriiption === 'tg' }">
                <input type="radio" name="tg" @click="selectLangForDescriiptionHandler('tg')" />
                {{ $t('tariffs.locale.tg') }}
              </label>
            </div>
            <label for="description">{{ $t('tariffs.subscriptionDescriptionAdvice') }}</label>
          </div>

          <transition name="langTextareaForDescriptionINtariffsModal" mode="out-in">
            <div v-if="showTextareaForDescription">
              <textarea
                :class="{ 'is-danger': errors.has('description') }"
                :disabled="isDisable"
                class="form-control form-group input"
                id="description"
                name="description"
                rows="6"
                v-model="tariff.description[selectedLangForDescriiption]"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('description') }"
                v-show="errors.has('ToS')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
          </transition>
        </div>
      </transition>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <button
          v-if="!isDisable"
          type="button"
          class="btn btn-outline-success mr-1"
          :disabled="errors.any() || muteEdit"
          @click="handleSave"
        >
          <span :class="{ invisible: muteEdit }">
            {{ isNew ? $t('general.create') : $t('general.save') }}
          </span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </button>
        <!-- Delete button is hidden for now -->

        <div>
          <button v-if="!isNew && !isDisable" type="button" class="btn btn-outline-danger mr-2" @click="deleteTariff">
            {{ $t('general.delete') }}
          </button>

          <button type="button" class="btn btn-outline-secondary" @click="cancelEditMode">
            {{ $t('general.close') }}
          </button>
        </div>
      </div>
    </modal>

    <close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Modal from '../../components/Modal.vue';
import portalTariffsService from '../../services/portalTariffsService';
import TablePaginationV2 from '../../components/table-pagination-v2.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import helpers from '../../helpers';
import CloseDialog from '../../components/close-dialog.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import portalProfilesService from '../../services/portalProfilesService';
import EmptyCellValue from '../../components/empty-cell-value.vue';

const TARIFFS_TYPES = { VOUCHER: 'voucher', SUBSCRIPTION: 'subscription' };
const TARIFFS_TYPES_LIST = [TARIFFS_TYPES.VOUCHER, TARIFFS_TYPES.SUBSCRIPTION];
const RECURRING_PERIODS = { DAY: 'day', WEEK: 'week', MONTH: 'month' };
const RECURRING_PERIODS_LIST = [RECURRING_PERIODS.DAY, RECURRING_PERIODS.WEEK, RECURRING_PERIODS.MONTH];

const watcher = new helpers.ChangesWatcher();

export default {
  name: 'Tariffs',
  components: { Modal, TablePaginationV2, CloseDialog, SwitchComponent, Multiselect, EmptyCellValue, TablePaginationV3 },
  data() {
    return {
      showTablePaginationV3Component: true,
      subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam: undefined,
      offset: 0,
      showTextareaForDescription: true,
      selectedInSelectorProfile: {},
      selectedLangForDescriiption: 'ru',
      canLoadMoreTariffs: true,
      totalResultTariffs: false,
      isModalOpen: false,
      isCloseModalOpen: false,
      isNew: true,
      tariff: {} /* setting initial value to false breaks something */,
      newTariff: {
        amount: '',
        name: '',
        session: '',
        speed: '',
        id: '',
        type: TARIFFS_TYPES.VOUCHER,
        description: {
          ru: '',
          en: '',
          tg: ''
        },
        recurring: false,
        recurring_period: '',
        profile: ''
      },
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }
      }
    };
  },
  methods: {
    selectLangForDescriiptionHandler(lang) {
      this.selectedLangForDescriiption = lang;
      this.showTextareaForDescription = false;
      this.$nextTick(() => {
        this.showTextareaForDescription = true;
      });
    },
    // resetAllFilters() {
    //   this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'name',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   }
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },

    resetAllFilters() {
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        // location: {
        //   data: {},
        //   with_childs: false,
        // }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    profileSelectHandler(profile) {
      this.errors.remove('profile');
      this.tariff.profile = profile.id;
    },
    getProfileObjectByID(id) {
      if (this.portalProfilesList && id) {
        const result = this.portalProfilesList.find((item) => item.id === id);
        if (result) {
          return result;
        }
        return {};
      }
      return {};
    },
    getProfileNameByID(id) {
      if (this.portalProfilesList && id) {
        const result = this.portalProfilesList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    addKeysToDescriptionObjectIfNotExists(descriptionObject) {
      try {
        let returnedObject = {
          ru: '',
          en: '',
          tg: ''
        };
        if (!descriptionObject || typeof descriptionObject !== 'object') {
          return returnedObject;
        }
        if (typeof descriptionObject === 'object' && Object.keys(descriptionObject).length === 0) {
          return returnedObject;
        }
        if (typeof descriptionObject === 'object' && Object.keys(descriptionObject).length !== 0) {
          // console.log('thrid condition')
          returnedObject = descriptionObject;
          if (!Object.prototype.hasOwnProperty.call(descriptionObject, 'ru')) {
            returnedObject.ru = '';
          }
          if (!Object.prototype.hasOwnProperty.call(descriptionObject, 'en')) {
            returnedObject.en = '';
          }
          if (!Object.prototype.hasOwnProperty.call(descriptionObject, 'tg')) {
            returnedObject.tg = '';
          }
        }
        return returnedObject;
      } catch (e) {
        console.log(e);
        return descriptionObject;
      }
    },
    tariffTypeSelectorHandler(event) {
      if (event.target?.value === this.tariffsTypes.VOUCHER) {
        // если переключаемся на ваучер - подчищаем специфичные для подписки поля
        this.clearSubscriptionSpecificFields();
      }
    },
    recurringSwitcherSwitchedHandler(value) {
      // console.log(value);
      // если включаем  свитчер recurring, а в recurring_period ничего не выбрано -
      // ставим туда по умолчанию месяц
      if (value === true && this.tariff.recurring_period === '') {
        this.tariff.recurring_period = this.recurringPeriods.MONTH;
      }
    },
    clearSubscriptionSpecificFields() {
      // console.log('clear')
      // подчищаем поля спецефичные для типа Подписка, когда выбираем тип Ваучер
      this.tariff.recurring = false;
      this.tariff.recurring_period = '';
    },
    getTariffTypeWithI18n(tariffType) {
      try {
        const type = helpers.capitalizeFirstLetter(tariffType);
        return this.$t(`tariffs.tariffType${type}`);
      } catch (e) {
        console.log(e);
        return tariffType;
      }
    },
    getRecurringPeriodWithI18n(recurringPeriod) {
      try {
        const type = helpers.capitalizeFirstLetter(recurringPeriod);
        return this.$t(`tariffs.recurringPeriod${type}`);
      } catch (e) {
        console.log(e);
        return recurringPeriod;
      }
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalTariffsService.getTariffs(this, { query: true });
      portalTariffsService.getTariffs(this, { query: true, useLocationFilter: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMoreTarrifs = true;
      this.$store.commit('portalTariffsListClean');
    },
    // openModal(isNew, tariff) {
    //   this.selectedInSelectorProfile = {};
    //   this.$validator.reset();
    //   this.isModalOpen = true;
    //   if (isNew) {
    //     this.isNew = true;
    //     this.tariff = { ...this.newTariff };
    //   } else {
    //     this.isNew = false;
    //     // console.log('this.addKeysToDescriptionObjectIfNotExists(tariff.drcnfnb lescription)', this.addKeysToDescriptionObjectIfNotExists(tariff.description))
    //     this.tariff = { ...tariff, description: this.addKeysToDescriptionObjectIfNotExists(tariff.description) };
    //     this.selectedInSelectorProfile = this.getProfileObjectByID(tariff.profile);
    //   }
    //   watcher.reset();
    // },

    openModal(isNew, tariff) {
      this.selectedInSelectorProfile = {};
      this.$validator.reset();
      this.isModalOpen = true;
      if (isNew) {
        this.isNew = true;
        this.tariff = {
          ...JSON.parse(JSON.stringify(this.newTariff)),
          description: JSON.parse(JSON.stringify(this.newTariff.description))
        };
      } else {
        this.isNew = false;
        this.tariff = {
          ...JSON.parse(JSON.stringify(tariff)),
          description: JSON.parse(JSON.stringify(this.addKeysToDescriptionObjectIfNotExists(tariff.description)))
        };
        this.selectedInSelectorProfile = this.getProfileObjectByID(tariff.profile);
      }
      watcher.reset();
    },

    handleSave() {
      this.$validator.validate().then((valid) => {
        if (
          typeof this.selectedInSelectorProfile === 'object' &&
          Object.keys(this.selectedInSelectorProfile).length !== 0
        ) {
          if (valid) {
            if (this.isNew) {
              this.createTariff(this.tariff);
            } else {
              this.updateTariff(this.tariff);
            }
          }
        } else {
          this.errors.add({
            field: 'profile',
            msg: this.$t('tariffs.noProfileSelectedError')
          });
        }
      });
    },
    cancelEditMode() {
      this.isModalOpen = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
      this.errors.clear();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    correctValuesTypes(tariff) {
      return {
        ...tariff,
        amount: parseInt(tariff.amount, 10),
        session: parseInt(tariff.session, 10),
        speed: parseInt(tariff.speed, 10)
      };
    },
    updateTariff(tariff) {
      portalTariffsService.updateTariff(this, this.correctValuesTypes({ ...tariff }));
    },
    createTariff(tariff) {
      portalTariffsService.createTariff(this, helpers.removeID(this.correctValuesTypes({ ...tariff })));
    },
    deleteTariff() {
      portalTariffsService.deleteTariff(this, this.tariff);
    },
    getWithQuery() {
      this.clearQuery();
      // portalTariffsService.getTariffs(this, { query: true });
      portalTariffsService.getTariffs(this, { query: true, useLocationFilter: true });
    }
  },
  watch: {
    tariff: {
      deep: true,
      handler() {
        watcher.tick();
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },

    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive() {
      return this.filters.limit !== 30 || this.filters.sort_by !== 'name' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    openedInModalTariffInType() {
      return this.tariff.type;
    },
    isSubscribtionOpenedInModal() {
      return this.openedInModalTariffInType === this.tariffsTypes.SUBSCRIPTION;
    },
    isVoucherOpenedInModal() {
      return this.openedInModalTariffInType === this.tariffsTypes.VOUCHER;
    },
    tariffsTypesList() {
      return TARIFFS_TYPES_LIST;
    },
    tariffsTypes() {
      return TARIFFS_TYPES;
    },
    recurringPeriods() {
      return RECURRING_PERIODS;
    },
    recurringPeriodsList() {
      return RECURRING_PERIODS_LIST;
    },
    muteEdit() {
      return this.$store.state.mutePortalTariffEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    tariffsList() {
      return this.$store.state.portalTariffsList;
    },
    isTariffsLoading() {
      return this.$store.state.loadingPortalTariffs;
    },
    portalProfilesList() {
      return this.$store.state.portalProfilesList ? this.$store.state.portalProfilesList : [];
    },
    isPortalProfilesLoading() {
      return this.$store.state.loadingPortalProfiles;
    }
  },
  // async created() {
  //   this.clearQuery();
  //   portalTariffsService.getTariffs(this, { query: true });
  //   this.$store.commit('portalProfilesListClean');
  //   await portalProfilesService.getPortalProfilesFromTariffs(this);
  //   if (this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam &&
  //     typeof this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam === 'object') {
  //     // тут занимаемся отлавливанием объекта подписки, по которому кликнули в разделе Пользователи, чтобы открыть его в модалке
  //     this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam = JSON.parse(JSON.stringify(this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam));
  //     if (this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam) {
  //       let tariffForOpenInModal;
  //       for (const tariff of this.tariffsList) {
  //         if (tariff.id === this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam.id) {
  //           tariffForOpenInModal = tariff;
  //         }
  //       }
  //       if (tariffForOpenInModal) {
  //         this.openModal(false, tariffForOpenInModal);
  //       } else {
  //         console.log('not found');
  //       }
  //       this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam = undefined;
  //     }
  //   }
  // }
  async created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam &&
      typeof this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam === 'object') {
      inHeaderLocationSelectedApplying = false;
    }
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }

    this.clearQuery();
    // portalTariffsService.getTariffs(this, { query: true });
    portalTariffsService.getTariffs(this, {query: true, useLocationFilter: true});
    this.$store.commit('portalProfilesListClean');
    await portalProfilesService.getPortalProfilesFromTariffs(this);
    if (this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam &&
      typeof this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam === 'object') {
      // тут занимаемся отлавливанием объекта подписки, по которому кликнули в разделе Пользователи, чтобы открыть его в модалке
      this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam = JSON.parse(JSON.stringify(this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam));
      if (this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam) {
        let tariffForOpenInModal;
        for (const tariff of this.tariffsList) {
          if (tariff.id === this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam.id) {
            tariffForOpenInModal = tariff;
          }
        }
        if (tariffForOpenInModal) {
          this.openModal(false, tariffForOpenInModal);
        } else {
          console.log('not found');
        }
        this.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam = undefined;
      }
      delete this.$route.params.subscriptionObjectSelectedInUserAccountsAndPassedWithRouteParam
    }
  }
};
</script>

<style lang="scss">
.loader-backdrop-portaltariffs:after {
  position: absolute;
  top: 30px;
}
.langTextareaForDescriptionINtariffsModal-enter-active,
.langTextareaForDescriptionINtariffsModal-leave-active {
  transition: opacity 0.3s;
}

.langTextareaForDescriptionINtariffsModal-enter,
.langTextareaForDescriptionINtariffsModal-leave-to {
  opacity: 0;
}
.transitionINtariffsModal-enter-active,
.transitionINtariffsModal-leave-active {
  transition: opacity 0.3s;
}

.transitionINtariffsModal-enter,
.transitionINtariffsModal-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
.profile-selector {
  z-index: 1001;
}
</style>
